import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Testimonials from "../components/Testimonials";
import { CallToAction } from "../components/CallToAction";
import Faqs from "../components/Faqs";
import { Helmet } from "react-helmet";

export const Impress = () => {
  return (
    <Layout>
      <Helmet>
        <title>Get all the bells and whistles on your website | Sixvees</title>
        <meta
          name="description"
          content="Get the most out of the web by having an online store or booking system. Give your customers the convenience of shopping online and grow your sales."
        ></meta>
      </Helmet>
      <main>
        <div className="page-header sr-only">
          <h1>Impress</h1>
        </div>
        <section className="primary-content">
          <div className="primary-content__content">
            <div className="container">
              <h2>Get all the bells and whistles with our Impress package</h2>
              <p>
                If you need something special then you are in the right place.
                Get the most out of the web by having an online store or booking
                system. Give your customers the convenience of shopping online
                and watch your sales grow.
              </p>
              <p>
                Our Impress websites have no limits. If you can dream it, we can
                build it.
              </p>
            </div>
          </div>
          <div className="icon-scroll" />
        </section>

        <section className="bg-light text-dark pt-0 dark-strip-top">
          <div className="container">
            <h2
              className="heading-sm mb-5 text-white-stroke aos-init aos-animate"
              data-aos="fade-right"
            >
              Impress website features
            </h2>
            <div
              className="row justify-content-between pt-4 mb-4 aos-init aos-animate"
              data-aos="zoom-in-up"
              data-aos-anchor-placement="top-center"
            >
              <div className="col-lg-5 ">
                <img
                  src="/images/104921455_m-e1572223560294-575x887.jpg"
                  alt="Freakshake. Extreme milkshake."
                  className="img-fluid product"
                />
              </div>
              <div className="pt-4 pt-lg-0 col-lg-6 d-flex align-items-center">
                <div>
                  <div className="lead mb-4">
                    <p>
                      Want to sell online and have all the bells and whistles?
                      Bring your vision to life with a custom website design. If
                      you can dream it, we can build it.
                    </p>
                  </div>
                  <ul className="fa-ul ml-4 mb-5 lead">
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Get your own custom design
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Sell your products and services online with an e-commerce
                      store
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Allow your customers to book online
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Integrate your website with a third party
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-check-circle text-primary"></i>
                      </span>
                      Automate repetitive tasks and save time
                    </li>
                  </ul>

                  <Link
                    to="/get-in-touch/"
                    className="btn btn-outline-success mt-3 text-uppercase"
                  >
                    Free quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faqs />
        <Testimonials />
        <CallToAction text="No sure yet? Meet with us for free coffee and advice." />
      </main>
    </Layout>
  );
};

export default Impress;
